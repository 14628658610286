<template>
  <div>
    <vue-html2pdf
      :show-layout="true"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="hee hee"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <section class="pdf-item">
          <div style="">
            <table
              style="margin-left: 0; margin-right: 0; width: auto"
              class="no-border"
            >
              <tr>
                <td colspan="3">
                  <img
                    style="width: 4cm"
                    :src="logo"
                  />
                </td>
              </tr>
              <tr>
                <td style="width: 4cm">Document No.</td>
                <td style="width: 0.5cm">:</td>
                <td>{{ cpar_header.document_no_ }}</td>
              </tr>
              <tr>
                <td style="width: 4cm">Document Date</td>
                <td style="width: 0.5cm">:</td>
                <td>
                  {{ cpar_header.document_date }}
                </td>
              </tr>
              <tr>
                <td style="width: 4cm">Perihal</td>
                <td style="width: 0.5cm">:</td>
                <td>
                  {{ cpar_header.source_document }}
                  -
                  {{ cpar_header.document_type }}
                </td>
              </tr>
              <tr>
                <td style="width: 4cm">Divisi/Area</td>
                <td style="width: 0.5cm">:</td>
                <td>{{ cpar_header.area_code }}</td>
              </tr>
            </table>
            <hr style="border: 1px solid #ddd" />

            <div style="height:1000px;background-color:#ddd"></div>
          </div>
        </section>
      </section>
    </vue-html2pdf>

    <button @click="generateReport()">Generate</button>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: {
    VueHtml2pdf,
  },
  data: () => ({
    id: null,
    loading: false,
    cpar_header: {},
    cpar_item: {},
    cpar_attachment: {},
    cpar_approval: {},
    user: {},
    logo: 'https://api.betonperkasa.com/logo.png'
  }),
  methods: {
    async getDetail(refresh = false) {
      this.loading = true;

      this.cpar_header = {};

      if (refresh == true) this.AxiosStorageRemove("GET", "cpar/detail");

      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.id,
        },
        cacheConfig: true,
      };
      await this.$axios
        .get("cpar/detail", config)
        .then((res) => {
          this.cpar_header = res.data.data.cpar_header;
          this.cpar_item = res.data.data.cpar_item;
          this.cpar_attachment = res.data.data.cpar_attachment;
          this.cpar_approval = res.data.data.cpar_approval;
          this.user = res.data.data.user;

          this.showLoadingOverlay(false);

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
  beforeMount() {
    if (typeof this.$route.query.id !== "undefined") {
      this.id = this.$route.query.id;
    } else {
      this.id = this.$route.params.id;
    }
  },
  mounted() {
    this.getDetail(true);
  },
};
</script>
